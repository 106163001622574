<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../../helper/Gen';

    export default {
        extends: BaseVue,
        data() {},
        mounted() {},
        methods: {},
        watch: {},
    };
</script>

<template>
	<section id="content" class="overflow-none">
		<div class="content-wrap">
			<section class="section not-found nobg nobottommargin notopmargin">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-md-6 order-1 order-md-0">
							<div class="wrap_notfound">
								<h1>Halaman yang anda cari tidak dapat ditemukan</h1>
								<p>Beberapa Tautan ini mungkin bisa membantu</p>
								<div class="nf-links">
									<ul>
										<li><router-link :to="{name:'ProfilYayasan'}">{{ web.mn_profile }}</router-link></li>
										<li><router-link :to="{name:'Program'}">{{ web.mn_program }}</router-link></li>
										<li><router-link :to="{name:'News'}">{{ web.mn_berita }}</router-link></li>
										<li><router-link :to="{name:'Contact'}">{{ web.mn_contact }}</router-link></li>
									</ul>
								</div>
							</div>
							<router-link :to="{name:'index'}" class="btn_green">Kembali ke Beranda</router-link>
						</div>
						<div class="col-md-6 order-0 order-md-1">
							<VImg :src="baseUrl+'/frontend/images/notfound.png'" alt="Image 404"></VImg>
						</div>
					</div>
				</div>
			</section>
		</div>
	</section>
</template>